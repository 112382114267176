var prodcat = prodcat || {};

prodcat.data = prodcat.data || {};

(function ($, Drupal, document) {
  var isSoldOut = function (sku) {
    return !!sku.isSoldOut;
  };
  var isShoppable = function (sku) {
    return !!sku.isShoppable;
  };
  var setClasses = function (sku) {
    var classes = [];

    if (isSoldOut(sku)) {
      classes.push('inv-status--sold-out');
    }

    if (!isShoppable(sku)) {
      classes.push('inv-status--not-shoppable');
    }

    return classes;
  };

  Drupal.behaviors.customCollectionProductShadePickerV1 = {
    attach: function (context) {
      $('.js-product-shade', $('.js-custom-collection-product-shade-picker-v1', context)).each(function () {
        var $this = $(this);
        var skuId = $this.data('sku');
        var sku;
        var classes;

        if (!skuId) {
          return false;
        }

        if (skuId) {
          sku = prodcat.data.getSku(skuId);

          if (!sku) {
            return false;
          }
        }

        classes = setClasses(sku);

        if (classes.length > 0) {
          $this.addClass(classes.join(' '));
        }
      });
    }
  };

  $(document).on('click', '.js-product-shade', function (event) {
    event.preventDefault();

    var $this = $(this);
    var skuId = $this.data('sku');
    var skuData;
    var $product = $();

    if (skuId) {
      skuData = prodcat.data.getSku(skuId);

      if (skuData) {
        $product = $this.closest('.js-product');
        $product.trigger('product.skuSelect', [skuData.SKU_BASE_ID]);
      }
    }
  });

  $(document).on('customCollection:quickshopLaunch', function () {
    $('.js-product-shade:first-of-type', this).addClass('shade-active active');
  });

  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    var $selectedShade = $('.js-product-shade[data-sku="SKU' + skuBaseId + '"]', this);

    $('.js-product-shade', this).removeClass('shade-active');

    $selectedShade.addClass('shade-active');

    $('.js-shadename', this).text($selectedShade.text());
  });
})(jQuery, Drupal, document);
